import React from "react"
import Navbar from "./navbar";
import Footer from "./footer";


export default class Layout extends React.Component{
	render (){
		return (
			<>
			<Navbar />
				{this.props.children}			
			<Footer/>
			</>
			)
	}
}