import React from "react"

export default class ContentSection extends React.Component {
	render() {
		return (
			<div className={`section ${this.props.classes || ''}`}>
				<div className="container">
					<h6>{this.props.title}</h6>
					<hr />
					{this.props.children}
				</div>
			</div>
		)
	}
}