import React from "react";

export default class Footer extends React.Component {
	render() {
		return (
			<footer>
				<div className="container">
					<a href="https://t.me/ducatofficial" target="_blank" rel="noreferrer noopener">Telegram</a> ▪ <a href="#">Twitter</a> ▪ <a href="#">Github</a>
				</div>
			</footer>
		)
	}
}