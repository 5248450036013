import React from "react";
import { Helmet } from "react-helmet";
// import seoImage from "../../static/change-buy-bitcoin-compressor.png";

export default class SEO extends React.Component {
	render () {
		const seo = {
			title: this.props.title || "Ducat",
			description: this.props.description || "",
			keywords: this.props.keywords || "",
			twitterUsername: "@ducat",
			image: this.props.seoImage,
			article: this.props.article || false,
		}
		return(
			<>
				<Helmet title={ seo.title } titleTemplate="%s · Ducat">
					{seo.description && (
		              <meta property="og:description" content={seo.description} />
		            )}
		            {(seo.article) && (
		              <meta property="og:type" content="article" />
		            )}
		            {seo.image && <meta property="og:image" content={seo.image} />}
		            <meta name="twitter:card" content="summary_large_image" />
		            {seo.twitterUsername && (
		              <meta name="twitter:creator" content={seo.twitterUsername} />
		            )}
		            {seo.title && <meta name="twitter:title" content={seo.title} />}
		            {seo.description && (
		              <meta name="twitter:description" content={seo.description} />
		            )}
		            {seo.image && <meta name="twitter:image" content={seo.image} />}
				</Helmet>
			</>
		)
	}
}